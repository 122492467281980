<template>
  <div>
    <div
      v-for="(item, index) in [
        {
          label: '进件金额（万元）',
          value: columnAmount(form.applyAmount / 10000),
          column0: {
            label: '进件笔数(笔)',
            value: columnAmount(form.applyCount, {
              fixed: 0,
            }),
          },
          column1: {
            label: '件均金额(万元)',
            value: columnAmount(form.applyAmount / form.applyCount / 10000),
          },
        },

        {
          label: '额度批复（万元）',
          value: columnAmount(form.approvaledAmount / 10000),
          column0: {
            label: '批复笔数(笔)',
            value: columnAmount(form.approvaledCount, {
              fixed: 0,
            }),
          },
          column1: {
            label: '件均金额(万元)',
            value: columnAmount(
              form.approvaledAmount / form.approvaledCount / 10000
            ),
          },
        },

        {
          label: '成交金额（万元）',
          value: columnAmount(form.loanedAmount / 10000),
          column0: {
            label: '成交笔数(笔)',
            value: columnAmount(form.loanedCount, {
              fixed: 0,
            }),
          },
          column1: {
            label: '件均金额(万元)',
            value: columnAmount(form.loanedAmount / form.loanedCount / 10000),
          },
        },

        //
      ]"
      :key="index"
      class="
        bg_col_fff
        bor_rad_8
        box_sha_0_0_8_8_black_80_per
        p_16
        m_0_16 m_t_16
      "
    >
      <div class="flex_1 font_16 font_bold">{{ item.label }}</div>

      <div class="font_24 font_bold m_t_8">{{ item.value }}</div>

      <div class="dis_flex m_t_8">
        <div class="flex_1 dis_flex font_12">
          <div>{{ item.column0.label }}</div>
          <div class="col_66BB6A font_bold m_l_8">{{ item.column0.value }}</div>
        </div>

        <div class="flex_1 dis_flex font_12">
          <div>{{ item.column1.label }}</div>
          <div class="col_66BB6A font_bold m_l_8">{{ item.column1.value }}</div>
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
import { columnAmount } from '@/utils/tools';

import { brokerDashboardLoanGeneralStatisticsPost } from '@/api/index';

export default {
  components: {},
  data() {
    return {
      form: {},

      //
    };
  },
  computed: {},
  mounted() {
    this.getLoanGeneralStatistics();

    //
  },
  methods: {
    columnAmount,

    // 获取 进件概况统计
    async getLoanGeneralStatistics() {
      this.form = await brokerDashboardLoanGeneralStatisticsPost();
    },

    //
  },
};
</script>
